import { useTranslate } from "../../../../customHooks";
import { usePermissions } from '../../../../customHooks';
import { useCallback, useState } from "react";
import { toast } from "../../../../components";
import { FormLayout, Input, SubmitButton } from "@panwds/react-form";
import { isEmpty } from 'lodash';
import { useUpdateFirewallMutation } from "../../../../redux/services/firewalls-service";
import { extractFieldsWithConfig } from "../../../../utils/utils";
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Banner, Body, Button, Card, CardBody, CardHeader, CopyButton, Link, LoadingPanel, Tooltip } from "@panwds/react-ui";
import { FirewallEndpointsTable } from '../Edit';
import { EndpointsCreate } from "./EndpointComponents/EndpointsCreate";
import { EndpointsEdit } from "./EndpointComponents/EndpointsEdit";
import { produce } from "immer";
import { firewallStyles } from "../../style";
import { FirewallUpdateBanner } from "../../FirewallUpdateBanner";
import { FirewallAllowlistedAccounts } from "./EndpointComponents/FirewallAllowlistedAccounts";
import { useIntl } from "react-intl";
import { InfoIcon, SettingsIcon } from "@panwds/icons";

type PanelType = "" | "createEndpoint" | "editEndpoint" | "manageAllowListedAccount";
type EndpointAction = "" | "createEndpoint" | "deleteEndpoint" | "editEndpoint" | "firewallUpdating";

const messageWithLineBreak = (message: string) => {
    return message.split('{newline}').map((part, index) => (
        <React.Fragment key={index}>
            {part}
            {index < message.split('{newline}').length - 1 && <br />}
        </React.Fragment>
    ));
};

const FirewallEndpoints = (props: { firewallData: Record<string, any>, isLoading: boolean, accounts: Record<string, any>[], refreshFirewallData: () => void }) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const firewallUpdating = props?.firewallData?.Status?.FirewallStatus === "UPDATING" || '';
    const [sidePanel, setSidePanel] = useState<PanelType>("");
    const [editEndpointRecord, setEditEndpointRecord] = useState<object>({});
    const [endpointAction, setEndpointAction] = useState<EndpointAction>((firewallUpdating) ? "firewallUpdating" : "");
    const [updaFirewall] = useUpdateFirewallMutation();
    const config = {
        Firewall: ['FirewallId', 'EndpointServiceName', 'Region', 'EgressNAT', 'Endpoints', 'AllowListAccounts', 'CustomerZoneIdList', 'UpdateToken', 'DeploymentUpdateToken'],
    };
    const classes = firewallStyles();
    const intl = useIntl();

    const endpointBannerMessage = intl.formatMessage({
        id: 'resources.firewallsV2.endpointManagement.endpointsBanner',
    });
    const allowlisteAwsAccountsBannerMessage = intl.formatMessage({
        id: 'resources.firewallsV2.endpointManagement.firewallAllowlistedAccountsBanner',
    });
    const endpointBannerWithLineBreaks = messageWithLineBreak(endpointBannerMessage);
    const allowlistedAccountsBannerWithLineBreaks = messageWithLineBreak(allowlisteAwsAccountsBannerMessage);

    // Memoized function to transform the default form values
    const transformFormDefaultValue = useCallback((endpointData) => {
        if (isEmpty(endpointData)) {
            return undefined;
        }
        // Extract fields from firewall describe response based on the config.
        // Extracts fields needed for firewall endpoints form.
        const newStateWithConfig = extractFieldsWithConfig(endpointData, config);
        const { AllowListAccounts } = newStateWithConfig?.Firewall;
        return {
            Firewall: {
                ...newStateWithConfig.Firewall,
                Endpoints: newStateWithConfig.Firewall?.Endpoints ?? [],
                AllowListAccounts: Array.isArray(AllowListAccounts) ?
                    AllowListAccounts?.join(",") :
                    AllowListAccounts
            }
        }
    }, []);

    const transformFormSubmitValue = useCallback((formData: Record<string, any>) => {
        if (isEmpty(formData)) {
            return;
        }
        const AllowListAccounts = formData.Firewall.AllowListAccounts;
        const transformedData = {
            ...formData.Firewall,
            AllowListAccounts: AllowListAccounts ? AllowListAccounts.split(",") : [],
        }
        if (transformedData?.CustomerZoneIdList) { delete transformedData.CustomerZoneIdList };
        if (transformedData?.EgressNAT) { delete transformedData.EgressNAT };

        return transformedData;
    }, []);

    const formMethods = useForm({defaultValues: transformFormDefaultValue(props.firewallData)});
    const {
        formState: { dirtyFields }, control, setValue, getValues, watch, reset,
    } = formMethods;

    const allowListedAwsAccounts = watch("Firewall.AllowListAccounts");
    const onSubmit = useCallback(
        async (data) => {
            try {
                const result = await updaFirewall({
                    payload: transformFormSubmitValue(data),
                }).unwrap();
                toast.success(translate(`resources.firewallsV2.endpointManagement.endpointUpdateSuccess`), { toastId: 'update-firewall-endpoints' });
                if (result) {
                    await props.refreshFirewallData();
                    setEndpointAction("");
                    setSidePanel("");
                }
            }
            catch (error: any) {
                toast.error(`${error?.code}: ${error?.error}`, { toastId: "update-firewall-endpoints" });
                setEndpointAction("");
                setSidePanel("");
            }
        }, [permissions]
    );

    const createEndpoint = useCallback(
        async (data) => {
            setValue("Firewall.Endpoints", [...getValues("Firewall.Endpoints"), data], {shouldDirty: true});
            setSidePanel("");
            setEndpointAction("createEndpoint");
        }, [setValue, getValues]
    );

    const deleteEndpoint = useCallback(
        (row) => {
            const currentEndpoints = getValues("Firewall.Endpoints");
            setValue("Firewall.Endpoints", currentEndpoints.filter(
                (endpoint) => endpoint?.EndpointId !== row?.EndpointId || endpoint?.SubnetId !== row?.SubnetId
            ), { shouldDirty: true });
            if (row?.EndpointId) {
                setEndpointAction("deleteEndpoint");
            }
        }, [setValue, getValues]
    );

    const editEndpoint = useCallback(
        async (newEndpointData) => {
            const currentEndpoints = getValues("Firewall.Endpoints");
            const updatedState = produce(currentEndpoints, (draft) => {
                const index = draft.findIndex(item => item.EndpointId === newEndpointData?.EndpointId); // Find the index of the object to replace
                if (index !== -1) {
                    if (typeof newEndpointData?.Prefixes?.PrivatePrefix?.Cidrs === "string") {
                        newEndpointData.Prefixes.PrivatePrefix.Cidrs = newEndpointData.Prefixes.PrivatePrefix.Cidrs.split(",");
                    }
                  draft[index] = newEndpointData; // Replace the object at the found index with newObject
                }
            });
            setValue("Firewall.Endpoints", updatedState, {shouldDirty: true});
            setEndpointAction("editEndpoint");
            setSidePanel("");
        }, [setValue, getValues]
    );

    const setEditEndpointData = useCallback(
        async (row) => {
            setEditEndpointRecord(row);
            setSidePanel("editEndpoint");
        }, []
    );

    const handleCancel = () => {
        reset(transformFormDefaultValue(props.firewallData));
        setEndpointAction("");
    };

    return (
        <div className="tw-relative">
            {props?.isLoading &&
                <div className="tw-absolute tw-inset-0 tw-right-0 tw-flex tw-justify-center tw-items-center tw-backdrop-blur-sm tw-z-50" style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                    <div className="tw-w-6 tw-h-6">
                        <LoadingPanel />
                    </div>
                </div>
            }
            {firewallUpdating &&
                <FirewallUpdateBanner refresh={props.refreshFirewallData} />
            }
            <div className={classes.fwEditHeader}>{translate(`resources.firewallsV2.endpointManagement.title`)}</div>
            <div className={firewallUpdating ? classes.fwReadOnly : ""}>
                <div className="tw-pt-4">
                    <Card>
                        <CardHeader addClassName="tw-border-b-2"
                            title={<span className="tw-font-medium">{`${translate(`resources.firewallsV2.endpointManagement.allowListedAccounts`)} (${props?.firewallData?.Firewall?.AllowListAccounts?.length || 0}/100)`}</span>}
                            actions={<>
                                <Button
                                    icon={<SettingsIcon size="sm" />}
                                    dataTestId="create-endpoint"
                                    onClick={() => {setSidePanel('manageAllowListedAccount') } }
                                    appearance="primary"
                                    disabled={!isEmpty(dirtyFields) || firewallUpdating}
                                >
                                    {translate(`resources.firewallsV2.endpointManagement.manageAllowlistedAccount`)}
                                </Button>
                        </>} />
                        <CardBody>
                            <>
                                <Banner showIcon type="page">
                                    {allowlistedAccountsBannerWithLineBreaks}
                                </Banner>
                                <div className="tw-pt-4 tw-flex tw-flex-row tw-gap-8 tw-items-center">
                                    <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                                        <Body>{translate(`resources.firewallsV2.endpointManagement.allowListedAccounts`)}</Body>
                                        <Tooltip label={translate(`resources.firewallsV2.endpointManagement.allowListedAccountsTooltip`)}>
                                            <InfoIcon size="sm"/>
                                        </Tooltip>
                                    </div>
                                    <div className="tw-flex tw-flex-row tw-items-center">
                                        <Body>{props?.firewallData?.Firewall?.AllowListAccounts?.join(', ')}</Body>
                                        <CopyButton text={props?.firewallData?.Firewall?.AllowListAccounts?.join(', ')} />
                                    </div>
                                </div>
                            </>
                        </CardBody>
                    </Card>
                </div>
                <div className="tw-pt-4">
                    <FormProvider {...formMethods}>
                        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                            <Card>
                                <CardHeader addClassName="tw-border-b-2"
                                    title={<span className="tw-font-medium">{`${translate('resources.firewallsV2.endpointManagement.endpoints')} (${props?.firewallData?.Firewall?.Endpoints?.length || 0})`}</span>}
                                    actions={<>
                                        <Button addClassName="tw-mr-2" onClick={handleCancel}>
                                            {translate(`generic.cancel`)}
                                        </Button>
                                        <SubmitButton disabled={isEmpty(dirtyFields)}>
                                            {translate(`generic.save`)}
                                        </SubmitButton></>
                                    }
                                />
                                <CardBody>
                                <>
                                    <div className="tw-ml-2">
                                        {endpointAction === "createEndpoint" &&
                                            <div className="tw-pb-4">
                                                <Banner
                                                    type="inline"
                                                    appearance="warning"
                                                    showIcon>
                                                    {translate(`resources.firewallsV2.endpointManagement.addEndpointsBanner`)}
                                                </Banner>
                                            </div>
                                        }
                                        {endpointAction === "editEndpoint" &&
                                        <div className="tw-pb-4">
                                                <Banner
                                                    type="inline"
                                                    appearance="warning"
                                                    showIcon>
                                                    {translate(`resources.firewallsV2.endpointManagement.editEndpointsBanner`)}
                                                </Banner>
                                            </div>
                                        }
                                        {endpointAction === "deleteEndpoint" &&
                                            <div className="tw-pb-4">
                                                <Banner
                                                    type="inline"
                                                    appearance="warning"
                                                    showIcon>
                                                    {translate(`resources.firewallsV2.endpointManagement.deleteEndpointsBanner`)}
                                                </Banner>
                                            </div>
                                        }
                                        <div className="tw-pb-4">
                                            <Banner showIcon type="inline" actions={<>
                                                <Link external href="https://docs.aws.amazon.com/vpc/latest/privatelink/privatelink-access-aws-services.html">
                                                    {translate(`generic.learnMore`)}
                                                </Link>
                                                </>}>
                                                {endpointBannerWithLineBreaks}
                                            </Banner>
                                        </div>
                                        <div className="tw-flex tw-flex-row tw-gap-8 tw-items-center">
                                            <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                                                <Body>{translate(`resources.firewallsV2.endpointManagement.endpointServiceName`)}</Body>
                                                <Tooltip label={translate(`resources.firewallsV2.endpointManagement.endpointServiceNameTooltip`)}>
                                                    <InfoIcon size="sm"/>
                                                </Tooltip>
                                            </div>
                                            <div className="tw-flex tw-flex-row tw-items-center">
                                                <Body>{props.firewallData?.Firewall?.EndpointServiceName}</Body>
                                                <CopyButton text={props.firewallData?.Firewall?.EndpointServiceName} />
                                            </div>
                                        </div>
                                    </div>
                                    <FormLayout>
                                        <Controller
                                            name="Firewall.Endpoints"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <FirewallEndpointsTable key={endpointAction} endpointAction={endpointAction} allowListedAwsAccounts={allowListedAwsAccounts} setEditEndpointData={setEditEndpointData} deleteEndpoint={deleteEndpoint} setSidePanel={setSidePanel} {...field} label="Custom Field" />
                                            )}
                                        />

                                        {/* <div className="tw-flex tw-justify-end">
                                            <Button addClassName="tw-mr-2" onClick={handleCancel}>
                                                {translate(`generic.cancel`)}
                                            </Button>
                                            <SubmitButton disabled={isEmpty(dirtyFields)}>
                                                {translate(`generic.save`)}
                                            </SubmitButton>
                                        </div> */}
                                    </FormLayout>
                                </>
                                </CardBody>
                            </Card>
                        </form>
                        {sidePanel === 'manageAllowListedAccount' && <FirewallAllowlistedAccounts
                            close={() => setSidePanel("")}
                            onSubmit={onSubmit}
                            fwRecord={transformFormDefaultValue(props.firewallData)}
                        />
                        }
                        {sidePanel === 'createEndpoint' && <EndpointsCreate
                            close={() => setSidePanel("")}
                            createEndpoint={createEndpoint}
                            allowlisteAwsAccounts={props?.firewallData?.Firewall?.AllowListAccounts}
                            zones={getValues('Firewall.CustomerZoneIdList') || []}
                            accounts={props?.accounts || []}
                            region={props.firewallData?.Firewall?.Region}
                        />
                        }
                        {sidePanel === 'editEndpoint' && <EndpointsEdit
                            close={() => setSidePanel("")}
                            editEndpoint={editEndpoint}
                            editEndpointData={editEndpointRecord}
                            disableEgress={!props.firewallData?.Firewall?.EgressNAT?.Enabled}
                        />
                        }
                    </FormProvider>
                </div>
            </div>
        </div>
    );
};export default FirewallEndpoints;
