import { Banner } from "@panwds/react-ui";
import { useTranslate } from "../../customHooks";
import { RedoIcon } from "@panwds/icons";

export const FirewallUpdateBanner = (props: any) => {
    const translate = useTranslate();
    return (
        <>
         <Banner
            actions={<><RedoIcon onClick={() => props.refresh()} size="sm"/></>}
            type="inline"
            appearance="warning"
            showIcon>
            {translate('resources.firewallsV2.firewallUpdating')}
        </Banner>
    </>
    );
};
