
import '@inovua/reactdatagrid-enterprise/index.css';
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from '@panwds/react-ui';
import jwt_decode from "jwt-decode";
import { useCallback, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { PANTitle, toast } from "../../components";
import { PANWDSTable } from '../../components/PANWDSElements';
import { dataProvider } from "../../dataProvider";
import { RouteUri } from '../../routeUri';
import { nameStyleCursor } from '../../layout/styles';
import { usePermissions, useTranslate } from '../../customHooks';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Emitter from "../../eventEmitter";
import { ReduxActions, ReduxResources } from "../../redux";
import { CircleCheckIcon, ExclamationTriangleIcon } from '@panwds/icons';

const useStyles = makeStyles((theme) => ({
  circlePending: {
    color: '#EF9700',
  },
  checkIcon: {
    color: "#33CCB8",
  },
}));

const UsersList = () => {
  const nameClass = nameStyleCursor();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const usersState = useAppSelector((state) => state.users);

  const [gridData, setGridData] = useState(usersState.listDetails.data);
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const history = useHistory();
  const title = translate(`resources.users.name`);
  const { permissions, rawPermissions } = usePermissions();
  const [nextToken, setNextToken]: any = useState();
  // @ts-ignore
  const [gridRef, setGridRef] = useState({
    current: {
      columns: []
    },
  });

  useEffect(() => {
    setGridData(usersState.listDetails.data);
    setNextToken(usersState.listDetails.nextToken);
    if (!usersState.listDetails.loading && loading) {
      setLoading(false);
    }
    if (usersState.listDetails.error) {
      toast.error(usersState.listDetails.error, { toastId: `users-getList` });
    }
  }, [usersState.listDetails.data, usersState.listDetails.loading]);


  const loadGridData = (needRefresh = false) => {
    setLoading(true);
    if (!permissions?.ListUsers) {
      if (permissions && !permissions?.ListUsers) {
        // TODO toast is not showing, but if we add toast container it will show
        // many times until permissions is loaded, need to rethink this logic
        toast.warning(translate("permissions.cantView"));
      }
      setLoading(false);
      return;
    }
    if ((gridData.length && nextToken) || !gridData.length || needRefresh) {
      if (!gridData.length || needRefresh) {
        setLoading(true);
      }

      dispatch(ReduxActions.fetchListDetails({ resource: ReduxResources.USER })({ data: ({ NextToken: (nextToken) ? nextToken : undefined }) }));
    } else {
      dispatch(ReduxActions.compareList({ resource: ReduxResources.USER })({}));
      setLoading(false);
    }
  }

  useEffect(() => {
    return () => {
      dataProvider.abort('users');
    }
  }, []);

  const getcolumns = () => [
    {
      accessor: 'UserName',
      Header: translate(`resources.users.fields.UserName`),
      columnSize: 2,
      noTooltip: true,
      render: ({ row }: any) => {
        return (
          <Tooltip
            label={row?.original?.UserName}>
              {!permissions?.UpdateUser || row?.original?.UserStatus && (row?.original?.UserStatus.toLowerCase() === "provisioned" || row?.original?.UserStatus.toLowerCase() === "staged") ?
              <span>{row?.original?.UserName}</span>
            :
            <span
              className={nameClass.blueColor}
              onClick={(evt) => onRowClick(row, evt)}
            >
              <span>{row?.original?.UserName}</span>
            </span>
          }

          </Tooltip>
        )
      },
      sortType: 'basic'
    },
    {
      accessor: 'Name',
      Header: translate(`resources.users.fields.Name`),
      columnSize: 2,
      noTooltip: true,
      render: ({ row }: any) => {
        return <Tooltip
          label={`${row?.original?.FirstName} ${row?.original?.LastName}`}>
            <span>
              {row?.original?.FirstName} {row?.original?.LastName}
            </span>
        </Tooltip>
      },
      sortType: 'basic'
    },
    {
      accessor: 'UserStatus',
      Header: translate(`resources.users.fields.UserStatus`),
      defaultFlex: 1,
      noTooltip: true,
      render: ({ row }: any) => {
        let userPending = row?.original?.UserStatus && (row?.original?.UserStatus.toLowerCase() === "provisioned" || row?.original?.UserStatus.toLowerCase() === "staged");
        let userActive = row?.original?.UserStatus && row?.original?.UserStatus.toLowerCase() === "active"
        return (
          <Tooltip
            label={(userPending) ? translate(`resources.users.fields.StatusPendingMessage`) : row?.original?.UserStatus}>
            <span>
              {(userActive) ?
                <> <CircleCheckIcon
                className={classes.checkIcon}
                data-metrics="cloudngfw-users-list-CircleCheck-img"
                size="sm"
              /> {row?.original?.UserStatus}</> :
                (userPending) ?
                <><ExclamationTriangleIcon size="xs" className={classes.circlePending} /> {translate('resources.users.fields.StatusPending')}</>
                : <>{row?.original?.UserStatus}</>
              }
            </span>
          </Tooltip>
        )
      },
      sortType: 'basic'
    },
    {
      accessor: 'Roles',
      Header: translate(`resources.users.fields.Roles`),
      columnSize: 7,
      noTooltip: true,
      render: ({ row }: any) => {
        let roles = row?.original?.Permissions?.map((item: any) => (item?.AccountId) ? `${(item?.AccountId)}/${item?.Policy}` : item?.Policy)
        let roleValues = (roles) ? roles.join(", ") : "";
        return <Tooltip label={roleValues}><span>{roleValues}</span></Tooltip>
      },
      sortType: 'basic'
    }
  ];

  const deleteAction = (selected: any) => {
    //@ts-ignore
    var userName: any = jwt_decode(localStorage.getItem("idToken"));
    if (selected && Array.isArray(selected)) {
      const successItems: any = [];
      const failedItems: any = [];
      setLoading(true);
      selected.map((item: any) => {
        if (item?.UserName) {
          if (item.UserName === userName?.email) {
            toast.info(translate(`resources.users.fields.SelfDelete`))
          } else {
            dataProvider.delete("users", { id: item?.UserName, previousData: item })
              .then(async (response: any) => {
                successItems.push(item);
              }).catch((e: any) => {
                failedItems.push({ item: item, e });
              }).finally(() => {
                if (failedItems.length + successItems.length === selected.length) { // request finished
                  dispatch(ReduxActions.deleteResource({ resource: ReduxResources.USER })({ deletedItems: successItems }));
                  if (successItems.length > 0) {
                    toast.success("User(s) deleted succesfully.");
                  }
                  if (failedItems.length > 0) {
                    toast.error(failedItems[0].e?.error?.error, { toastId: "users-delete" })
                  }
                }
              });
          }
        }
      });
    }
  }

  const onRowClick = useCallback((rowProps, event) => {
      history.push(RouteUri.UserEdit + "?username=" + encodeURIComponent(rowProps.original.UserName));
  }, [history]);

  useEffect(() => {
    /*
    dataProvider.get("accounts")
      .then(async (response: DataTypes.IFwaasApiResponse) => {
        //@ts-ignore
        let ret: DataTypes.IFwaasApiResponse = {};
        if (response?.data) {
          //@ts-ignore
          let accountIds = response.data?.AccountIds as string[];
          if (accountIds) {
            dataProvider.get("permissionpolicies").then((response: any) => {
              if (response?.data) {
                var allowedRoles = response?.data?.Permissions;
                let allowedRolesChoice: object[] = [];
                allowedRoles.map((item: any) => {
                  allowedRolesChoice.push(
                    (item?.AccountIdParam === "Mandatory") ? {
                      id: {
                        AccountId: accountIds[0],
                        Policy: item?.Policy,
                      },
                      name: `${accountIds[0]}/${item?.Policy}`,
                    } :
                      {
                        id: {
                          Policy: item?.Policy,
                        },
                        name: item?.Policy,
                      }
                  );
                  return item;
                });
                //@ts-ignore
                // setRoleChoice(allowedRolesChoice);
              }
            }).catch((e: any) => {
              toast.error(e?.error, { toastId: "permissionpolicies-get" });
            })
            return;
          }
        } else {
          toast.error(response?.error, { toastId: "accounts-get" });
        }
        return ret;
      })
      .catch((e: any) => {
        if (e?.noToast) {
          return;
        }
        toast.error(e?.error, { toastId: "accounts-get" });
      });
      */
  }, []);

  const toolbarActions: any[] = [];
  if (permissions?.DeleteUser) {
    toolbarActions.push({
      title: translate(`common.actions`),
      actionsMap: [{ menuText: translate(`common.delete`), handleAction: deleteAction, dataMetrics: "cloudngfw-users-delete-btn", },]
    });
  }
  if (permissions?.CreateUser) {
    toolbarActions.push({
      title: translate(`resources.users.fields.CreateButton`),
      actionsMap: [],
      dataMetrics: "cloudngfw-users-create-btn",
      handleButtonAction: () => history.push(RouteUri.UserCreate),
      type: 'button',
      appearance: 'primary'
    });
  }

  return (
    <>
      <PANTitle
        divider
        title={title}
        paddingContainer="16px"
      />
      <PANWDSTable
        setHandle={setGridRef}
        columns={getcolumns()}
        loadGridData={loadGridData}
        gridData={gridData}
        permissions={permissions}
        infiniteScroll={false}
        isBackgroundFetching={nextToken}
        showSelectGroupBy={false}
        title={title}
        subtitle={translate(`resources.users.fields.ListSubtitle`)}
        idProperty={"RuleStackName"}
        showToolbar={true}
        dropDownActionsArray={toolbarActions}
        showTileTitle={true}
        emptyTitle={translate(`resources.users.fields.EmptyTitle`)}
        emptySubtitle={translate(`resources.users.fields.EmptySubtitle`)}
        loading={loading}
        singleSelect={false}
        multiSelect={true}
        overflowTable={true}
        useOldToolbarButtons={true}
        offsetTableHeight={155}
        tableMinHeight={600}
        searchFilterRequired={true}
        dataMetrics="cloudngfw-users-table"
        dataTestId="cloudngfw-users-table"
      />
    </>
  );
}

export default withRouter(UsersList);
