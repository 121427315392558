import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders, API } from '../../api/apis';

export const userRolesService = createApi({
  reducerPath: 'userRolesApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: prepareHeaders,
  }),
  endpoints: (builder) => ({
    getUserRolesAndPermissions: builder.query<any[], void>({
      queryFn: async (_, queryApi, extraOptions, baseQuery) => {
        const responses = await Promise.allSettled([
          baseQuery({ url: API.FETCH_PERMISSION_POLICIES(), method: 'GET' }),
          baseQuery({ url: API.FETCH_XACCOUNT_ROLES(), method: 'GET' })
        ]);

        const permissionResponse: any = responses[0];
        const rolesResponse: any = responses[1];

        const errors: string[] = [];
        const allowedRolesChoices: any[] = [];

        if (permissionResponse.status === 'rejected') {
          errors.push('Failed to fetch permission policies');
        } else if (permissionResponse.status === 'fulfilled') {
          const permissionsData = permissionResponse.value.data.Response;
          const allowedRoles = permissionsData?.Permissions || [];
          const accountIds = rolesResponse.status === 'fulfilled' ? rolesResponse.value.data.Response.AccountIds : [];

          allowedRoles.forEach((item: Record<string, any>) => {
            if (item.AccountIdParam === 'Mandatory') {
              accountIds.forEach((account: string) => {
                allowedRolesChoices.push({
                  value: `${account}/${item?.Policy}`,
                  text: `${account}/${item.Policy}`,
                });
              });
            } else {
              allowedRolesChoices.push({
                value: `${item?.Policy}`,
                text: item.Policy,
              });
            }
          });
        }

        if (rolesResponse.status === 'rejected') {
          errors.push('Failed to fetch account roles');
        }

        if (errors.length) {
          return {
            error: {
              status: 500,
              data: {
                messages: errors,
                allowedRolesChoices,
              },
            },
          };
        }

        return { data: allowedRolesChoices };
      },
    }),
  }),
});

export const { useGetUserRolesAndPermissionsQuery } = userRolesService;
