import * as EndPoints from "./FwaasUriEndPoints";
import { ApplicationConfigManager } from "../types";
import { DeleteFirewallRequest, DescribeLogProfileRequest, ListFirewallRequest, ListLinkAccountRequest, ListRulesRequest, ListRuleStacksRequest, ListXAccountRequest, UpdateFirewallRequest, UpdateLogProfileRequest } from "../interfaces/interfaces";
import configuration from "../config.json"

export const prepareHeaders = (headers: Headers) => {
    const token = localStorage.getItem('idToken');
    const xApiKey = ApplicationConfigManager.getInstance().getXApiKey();
    headers.append('Authorization', token || '');
    headers.append('x-api-key', xApiKey);
    headers.append('Content-Type', 'application/json');
    return headers;
};

export const buildError = (response: any, exception: any, options: any = {}) => {
    let errInfo;
    if (response) {
        errInfo = {
            code: response.status,
            error: response?.data?.Reason || response?.data?.ResponseStatus?.Reason || "Something went wrong!"
        };
    } else {
        let message = exception?.body?.ResponseStatus?.Reason || exception?.body?.message || exception?.message || exception?.error || exception?.json?.message;
        errInfo = {
            code: 0,
            error: message || exception || "Something went wrong!"
        };
    }

    return { ...errInfo, ...options };
};

export function getResourceURL(resource: EndPoints.RESOURCE, params?: { [key: string]: string }, region?: string | null) {
    let BASE_URL: string = region
        ? ApplicationConfigManager.getInstance().getRegionAPIBaseUri(region)
        : ApplicationConfigManager.getInstance().getAPIBaseUri();

    switch (resource) {
        case EndPoints.RESOURCE.FIREWALL:
            return BASE_URL + EndPoints.URI_FIREWALLS;
        case EndPoints.RESOURCE.REGIONS:
            return  BASE_URL + EndPoints.URI_REGIONS_AZLIST;
        case EndPoints.RESOURCE.PERMISSION_POLICIES:
            return BASE_URL + EndPoints.URI_PERMISSION_POLICIES;
        case EndPoints.RESOURCE.XACCOUNT_ROLES:
            return BASE_URL + EndPoints.URI_XACCOUNT_ROLES;
        case EndPoints.RESOURCE.ACCOUNT:
            return BASE_URL + EndPoints.URI_LINK_ACCOUNTS;
        case EndPoints.RESOURCE.LOGPROFILE:
            return BASE_URL + EndPoints.LOGPROFILE;
        case EndPoints.RESOURCE.RULES:
            let url = BASE_URL + EndPoints.URI_RULES;
            if (params) {
                Object.keys(params).forEach(key => {
                    url = url.replace(`{${key}}`, params[key]);
                });
            }
            return url;
        case EndPoints.RESOURCE.LINKS:
            return BASE_URL + EndPoints.URI_INTEGRATIONS;
        case EndPoints.RESOURCE.RULESTACK:
            return BASE_URL + EndPoints.URI_RULESTACKS;
        case EndPoints.RESOURCE.LINKASSOCIATE:
            return BASE_URL + EndPoints.URI_LINK.replace("{firewall_id}", params?.firewall_id || "");
        case EndPoints.RESOURCE.RULESTACKASSOCIATE:
            return BASE_URL + EndPoints.URI_RULESTACK.replace("{firewall_id}", params?.firewall_id || "");
        default:
            throw new Error("Unsupported resource type");
    }
}

export const API = {
    FETCH_FIREWALLS: (payload: ListFirewallRequest | undefined) => {

        let resourceUrl = `${configuration.v2ApiUrl}/v2/config/ngfirewalls`;

        if (payload && payload.nexttoken) {
            resourceUrl = resourceUrl + `?maxresults=${payload?.maxresults || 25}&describe=true&region=all&sandboxregion=${payload?.region}&nexttoken=${payload?.nexttoken}`;
        } else {
            resourceUrl = resourceUrl + `?maxresults=${payload?.maxresults || 25}&describe=true&region=all&sandboxregion=${payload?.region}`;
        }
        if (payload && payload.rulestackname) {
            resourceUrl += '&rulestackname=' + payload.globalrulestackname;
        }
        return resourceUrl;
    },
    FETCH_FIREWALL_BY_ID: (fwid: string, region: string) => {
         //TODO: Fix the api endpoint once the cross region is fixed.
        // return `${getResourceURL(EndPoints.RESOURCE.FIREWALL)}/{fwid}`;
        if (!fwid || typeof fwid !== 'string') {
            throw new Error("Invalid firewall ID");
        }
        if (!region || typeof region !== 'string') {
            throw new Error("Invalid region");
        }
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls/${encodeURIComponent(fwid)}?region=${encodeURIComponent(region)}`;
    },
    FETCH_REGIONS: (payload: any | undefined) => {
        let resourceUrl = getResourceURL(EndPoints.RESOURCE.REGIONS);
        if (payload && payload.region) {
            resourceUrl += `?region=${payload.region}`;
        }
        return resourceUrl;
    },
    FETCH_PERMISSION_POLICIES: () => {
        return getResourceURL(EndPoints.RESOURCE.PERMISSION_POLICIES);
    },
    FETCH_XACCOUNT_ROLES: () => {
        return getResourceURL(EndPoints.RESOURCE.XACCOUNT_ROLES);
    },
    FETCH_XACCOUNT_ROLES_DETAILS: (payload: ListXAccountRequest | undefined) => {
        let resourceUrl = `${getResourceURL(EndPoints.RESOURCE.XACCOUNT_ROLES, {}, payload?.region)}/${payload?.accountId}?describeaccount=${payload?.describe}`;
        if (payload && payload.vpcId) {
            resourceUrl += '&vpcid=' + payload.vpcId;
        }
        return resourceUrl;
    },
    FETCH_RULES: (payload: ListRulesRequest) => {
        const url = getResourceURL(EndPoints.RESOURCE.RULES, {
            ruleStackName: payload.ruleStackName,
            ruleListName: payload.ruleListName
        });
        const params: any = {
            maxresults: payload.MaxResults || 1000,
        };
        if (payload.NextToken) {
            params.NextToken = payload.NextToken;
        }
        return {
            url,
            params,
        };
    },
    CREATE_FIREWALL: () => {
        //TODO: Fix the api endpoint once the cross region is fixed.
        // return `${getResourceURL(EndPoints.RESOURCE.FIREWALL)}?region=${region}`;
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls`;
    },
    UPDATE_FIREWALL: (payload: UpdateFirewallRequest) => {
        if (!payload || !payload?.FirewallId) {
            throw new Error("Invalid firewall ID");
        }
        if (!payload || !payload.Region) {
            throw new Error("Invalid region");
        }
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls/${encodeURIComponent(payload.FirewallId)}?region=${encodeURIComponent(payload.Region)}`;

    },
    DELETE_FIREWALL: (payload: DeleteFirewallRequest) => {
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls/${encodeURIComponent(payload.FirewallId)}?region=${encodeURIComponent(payload.Region)}`;

    },
    FETCH_LINK_ACCOUNTS: (payload: ListLinkAccountRequest | undefined) => {
        let resourceUrl = getResourceURL(EndPoints.RESOURCE.ACCOUNT, {}, payload?.region);

        if (payload && payload.nexttoken) {
            resourceUrl = resourceUrl + `?maxresults=${payload?.maxresults || 25}&nexttoken=${payload?.nexttoken}`;
        } else {
            resourceUrl = resourceUrl + `?maxresults=${payload?.maxresults || 25}`;
        }
        if (payload?.describe) {
            resourceUrl = resourceUrl + `&describe=true`;
        }
        return resourceUrl;
    },
    FETCH_LOG_PROFILE: (payload: DescribeLogProfileRequest | undefined) => {
        if (!payload || !payload?.FirewallId) {
            throw new Error("Invalid firewall ID");
        }
        if (!payload || !payload.Region) {
            throw new Error("Invalid region");
        }
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls/${encodeURIComponent(payload.FirewallId)}/logprofile?region=${encodeURIComponent(payload.Region)}`;
    },
    UPDATE_LOG_PROFILE: (payload: UpdateLogProfileRequest) => {
        if (!payload || !payload?.FirewallId) {
            buildError(null, new Error("Invalid firewall ID"));
        }
        if (!payload || !payload?.Region) {
            buildError(null, new Error("Invalid region"));
        }
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls/${encodeURIComponent(payload.FirewallId)}/logprofile?region=${encodeURIComponent(payload.Region)}`;
    },
    FETCH_RULESTACKS: (payload: ListRuleStacksRequest) => {
        return `${getResourceURL(EndPoints.RESOURCE.RULESTACK, {}, payload?.Region)}?candidate=${payload?.Candidate}&running=${payload?.Running}&scope=${payload?.Scope}`
    },
    FETCH_LINKS: () => getResourceURL(EndPoints.RESOURCE.LINKS, {}, ApplicationConfigManager.getInstance().getSupportedRegions().find(v => v.RegionCode === "us-east-1")?.RegionCode || ApplicationConfigManager.getInstance().getSupportedRegions()[0]?.RegionCode),
    ASSOCIATE_LINK: (firewall_id: string) => {
        if (!firewall_id || typeof firewall_id !== 'string') {
            throw new Error("Invalid firewall ID");
        }
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls/${encodeURIComponent(firewall_id)}/link`;
    },
    DISASSOCIATE_LINK: (firewall_id: string) => {
        if (!firewall_id || typeof firewall_id !== 'string') {
            throw new Error("Invalid firewall ID");
        }
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls/${encodeURIComponent(firewall_id)}/link`;
    },
    ASSOCIATE_RULESTACK: (firewall_id: string) => {
        if (!firewall_id || typeof firewall_id !== 'string') {
            throw new Error("Invalid firewall ID");
        }
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls/${encodeURIComponent(firewall_id)}/rulestack`;
    },
    DISASSOCIATE_RULESTACK: (firewall_id: string) => {
        if (!firewall_id || typeof firewall_id !== 'string') {
            throw new Error("Invalid firewall ID");
        }
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls/${encodeURIComponent(firewall_id)}/rulestack`;
    },
};
