import { FormLayout, Input, Select, SubmitButton } from '@panwds/react-form';
import { Banner, Button, SelectItem, Sheet, SheetBody } from "@panwds/react-ui";
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslate } from "../../../../../customHooks";
import { useGetXAccountsDetailsQuery } from '../../../../../redux/services/accounts-service';
import { intersection } from 'lodash';
import { toast } from 'react-toastify';

export const EndpointsCreate = (props: any) => {
    const { close, createEndpoint, allowlisteAwsAccounts, accounts, region } = props;
    const translate = useTranslate();

    //filter account list based on onboarded and allow listed accounts
    const getAccoutsList = useCallback(() => {
        if (accounts) {
            const allowedAccounts = (allowlisteAwsAccounts) ?
                Array.isArray(allowlisteAwsAccounts) ? allowlisteAwsAccounts : allowlisteAwsAccounts.split(",") : [];
            const filteredAccounts = intersection(accounts, allowedAccounts);
            return (filteredAccounts.map((account: any) => ({
                children: account,
                value: account,
            })));
        }
    }, [allowlisteAwsAccounts, accounts]);

    const formMethods = useForm({});
    const {
        formState: { isDirty, isValid, }, watch, setValue
    } = formMethods

    const accountIdValue = watch("AccountId");
    const vpcIdValue = watch("VpcId");

    //fetch vpcs when acc id changes
    const { data: vpcs, error: vpcsError, isFetching: vpcsLoading } = useGetXAccountsDetailsQuery({
        accountId: accountIdValue,
        region: region,
        describe: true,
    }, {
        skip: !accountIdValue,
    });

    //fetch subnets when vpc id changes
    const { data: subents, error: subentsError, isFetching: subnetsLoading } = useGetXAccountsDetailsQuery({
        accountId: accountIdValue,
        vpcId: vpcIdValue,
        region: region,
        describe: true,
    }, {
        skip: !vpcIdValue,
    });

    const getVpcList = useCallback(() => {
        if (vpcsLoading) {
            return [];
        }
        return ( vpcs?.items?.VpcInformations?.map((vpc: any) => ({
            children: vpc?.VpcName,
            value: vpc?.VpcId,
        })));
    }, [vpcs, vpcsLoading]);

    const getSubnetList = useCallback(() => {
        if (subnetsLoading) {
            return [];
        }
        return (subents?.items?.VpcInformations[0]?.SubnetInformations?.map((subnet: any) => ({
                children: `${subnet?.SubnetName || subnet?.SubnetId} (${subnet?.ZoneId})`,
                value: subnet?.SubnetId,
                //disable subnets that are not in the firewall's availability zones
                disabled: !props.zones.includes(subnet?.ZoneId)
            })
        ));
    }, [subents, subnetsLoading]);

    useEffect(() => {
        if (accountIdValue) {
            setValue("VpcId", "");
        }
    }, [accountIdValue]);

    useEffect(() => {
        if (vpcIdValue) {
            setValue("SubnetId", "");
        }
    }, [vpcIdValue]);

    const onSubmit = useCallback(
        async (data, e) => {
            e.preventDefault();
            createEndpoint(data);
        }, []
    );

    if (vpcsError) {
        toast.error(vpcsError?.error, {toastId: "enpoint-creates-get-vpcs"});
    }
    if(subentsError) {
        toast.error(subentsError?.error, {toastId: "enpoint-creates-get-subnets"});
    }

    return (
        <>
         <Sheet
            onClose={() => close()}
            title={translate(`resources.firewallsV2.endpointManagement.addEndpoint`)}
            isOpen
            showMask={true}
            position="md"
        >
            <SheetBody>
                <div style={{ position: 'relative' }}>
                    {accounts.length === 0 &&
                        <div className='tw-pb-4'>
                            <Banner
                                type="inline"
                                appearance="warning"
                                showIcon>
                                {translate(`resources.firewallsV2.endpointManagement.addEndpointNoOnboardedAccountBaner`)}
                            </Banner>
                        </div>
                    }
                    <FormProvider {...formMethods}>
                        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                            <FormLayout>
                                <div>
                                    <Select
                                        items={getAccoutsList()}
                                        label={translate(`resources.firewallsV2.endpointManagement.linkedAwsAccounts`)}
                                        name="AccountId"
                                        dataMetrics="cloudngfw-firewall-create-endpoint-account"
                                        placeholder={translate(`resources.firewallsV2.endpointManagement.linkedAwsAccountsPlaceholder`)}
                                        simpleFormat
                                        menuStyle={{ width: '10rem' }}
                                    />
                                    <Select
                                        items={getVpcList()}
                                        label={translate(`resources.firewallsV2.endpointManagement.vpcId`)}
                                        name="VpcId"
                                        dataMetrics="cloudngfw-firewall-create-endpoint-vpcId"
                                        isLoading={vpcsLoading}
                                        placeholder={translate(`resources.firewallsV2.endpointManagement.vpcSelectPlaceholder`)}
                                        simpleFormat
                                        disabled={!accountIdValue}
                                        menuStyle={{width: '15rem'}}
                                    />
                                    <Select
                                        items={getSubnetList()}
                                        label={translate(`resources.firewallsV2.endpointManagement.subnetId`)}
                                        name="SubnetId"
                                        dataMetrics="cloudngfw-firewall-create-endpoint-subnetId"
                                        isLoading={subnetsLoading}
                                        placeholder={translate(`resources.firewallsV2.endpointManagement.subnetSelectPlaceholder`)}
                                        simpleFormat
                                        disabled={!vpcIdValue}
                                        menuStyle={{ width: '20rem' }}
                                        description={translate(`resources.firewallsV2.endpointManagement.subnetIdDescription`)}
                                    />

                                    <div hidden>
                                        <Input
                                            name="Mode"
                                            label=''
                                            dataMetrics="cloudngfw-firewall-create-endpoint-mode"
                                            defaultValue='ServiceManaged'
                                        />
                                    </div>

                                    <div className="tw-flex tw-justify-end">
                                        <Button addClassName='tw-mr-2' appearance={"secondary"} onClick={() => props?.close()}>
                                            {translate("generic.cancel")}
                                        </Button>
                                        <SubmitButton disabled={!isValid || !isDirty}>{translate(`generic.add`)}</SubmitButton>
                                    </div>
                                </div>
                            </FormLayout>
                        </form>
                    </FormProvider>
                </div>
            </SheetBody>
        </Sheet>
    </>
    );
};
