import blockedDomains from "../blockedDomains.json";
import {isEmpty, memoize, uniq} from "lodash";

/* eslint-disable no-useless-escape */
const REGEX_LETTERS = new RegExp('^[a-zA-Z]+$');
const REGEX_IPV4 = /^(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/;
const REGEX_IPV4_CIDRS = /^(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;
const REGEX_PASSWORD = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,99}$/);
const REGEX_PASSWORD_RANGE = new RegExp(/^(?=.{8,99}$)/);
const REGEX_PASSWORD_UPPER = new RegExp(/^(?=.*[A-Z])/);
const REGEX_PASSWORD_LOWER = new RegExp(/^(?=.*[a-z])/);
const REGEX_PASSWORD_NUMBER = new RegExp(/^(?=.*[0-9])/);
const REGEX_PASSWORD_SPECIAL = new RegExp(/^(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])/);
const REGEX_FQDN = new RegExp('^[a-zA-Z0-9._-]+$'); // same regex as in messages.py/CreateFqdnListRequest
const REGEX_URL_CATEGORY = new RegExp('[^\s/$.?#].[^\s]*');

export const validatePasswordRegex = (password: string) => {
  const results = REGEX_PASSWORD.exec(password);
  return results === null ? false : true
};

export const ValidatePasswordRangeRegex = (password: string) => REGEX_PASSWORD_RANGE.exec(password) === null ? false : true;
export const ValidatePasswordUpperRegex = (password: string) => REGEX_PASSWORD_UPPER.exec(password) === null ? false : true;
export const ValidatePasswordLowerRegex = (password: string) => REGEX_PASSWORD_LOWER.exec(password) === null ? false : true;
export const ValidatePasswordNumberRegex = (password: string) => REGEX_PASSWORD_NUMBER.exec(password) === null ? false : true;
export const ValidatePasswordSpecialRegex = (password: string) => REGEX_PASSWORD_SPECIAL.exec(password) === null ? false : true;

export const validateFqdnList = (listName: string) => {
  const listRegEx = /^[a-zA-Z0-9._-]+$/;
  return listRegEx.test(listName);
}

export const validateFeedURL = (feedUrl: string) => {
  const feedUrlRegEx = /^(http|https):\/\/.+$/;
  return feedUrlRegEx.test(feedUrl);
}

export const validateIpV4Address = (ipv4Address: string) => {
  return REGEX_IPV4.test(ipv4Address);
}

export const validateIpV4CIDRSFormat = (ipv4CIDRSFormat: string) => {
  return REGEX_IPV4_CIDRS.test(ipv4CIDRSFormat);
}

export const multilineUniqueIpV4CIDRS = (ips: string = '') => {
  for(let ip of ips) {
    if (!REGEX_IPV4_CIDRS.test(ip)) {
      return `${ip} is not a valid IP address`;
    }
  }
  let ipSet = new Set(ips);
  if(ipSet.size !== ips.length) {
    return 'There are repeated IP address values'
  }
};

export const validateOnlyLetters = (value: string) => REGEX_LETTERS.test(value);

// new validations

export const isRequired = (value: string) => value ? undefined : "This field is required";

export const selectIsRequired = (value: string) => value ? undefined : "An option must be selected";

//@eslint-disable-next-line
export const isAlphaNumeric = (value: string) => (/^[a-zA-Z0-9-]+$/.test(value) ? undefined : 'Value must to be alpha numeric');

export const isAlphaNumericWithUnderscore = (value: string) => (/^[a-zA-Z0-9-_]+$/.test(value) ? undefined : 'Value must to be alpha numeric');

export const minLength = (min: number) => (value: string) => (value?.length <= min ? `This field must be at least ${min} characters long` : undefined);

export const maxLength = (max: number) => (value: string) => (value?.length >= max ? `This field must be at most ${max} characters long` : undefined);

export const maxElements = (max: number) => (elements: any[]) => (elements?.length >= max ? `This field must have at most ${max} elements` : undefined);

export const minValue = (min: number) => (value: string) => ((parseInt(value) < min) ? `Minimum value is ${min}` : undefined);

export const maxValue = (max: number) => (value: string) => ((parseInt(value) > max) ? `Maximum value is ${max}` : undefined);

//@eslint-disable-next-line
export const isPort = (value: string) => (/^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0][0-9]{1,4})|([0-9]{1,4}))$/.test(value) ? undefined : 'Invalid port number');

export const isIpV4 = (value: string) => (
  REGEX_IPV4.test(value) ? undefined : "Invalid IpV4 value"
);

export const isFqdnList = (value: string) => {
  if (value && value !== "") {
    if (typeof value === "object") {
      //@ts-ignore
      value = value.join("\n");
    }
    let list = value.split("\n");
    let result = list.every((element: string) => {
      return REGEX_FQDN.test(element);
    })
    return (result) ? undefined : "Is not a valid FQDN list (e.g. www.test.com)";
  } else {
    return "Is not a valid FQDN list (e.g. www.test.com)";
  }
};

export const isPrefixList = (value: string) => {
  if (value && value !== "") {
    if (typeof value === "object") {
      //@ts-ignore
      value = value.join("\n");
    }
    let list = value.split("\n");
    let result = list.every((element: string) => {
      return REGEX_IPV4_CIDRS.test(element);
    })
    return (result) ? undefined : "Invalid IpV4 value";
  } else {
    return "Is not a valid Prefix list";
  }
};

export const isUrlList = (value: string) => {
  if (value && value !== "") {
    if (typeof value === "object") {
      //@ts-ignore
      value = value.join("\n");
    }
    let list = value.split("\n");
    let result = list.every((element: string) => {
      return REGEX_URL_CATEGORY.test(element);
    })
    return (result) ? undefined : "Invalid URL";
  } else {
    return "Is not a valid URL list";
  }
};

//@eslint-disable-next-line
export const isCategoryName = (value: string) => (/^[a-zA-Z][0-9a-zA-Z-]*$/.test(value) ? undefined : 'Needs to be alpha numeric value');
//@eslint-disable-next-line
export const isFeedURL = (value: string) => /^(http|https):\/\/.+$/.test(value) ? undefined : "Invalid Feed URL (e.g. http://www.test.com)";

export const isProtocolAndPortArray = (value: any) => {
  let lists: any = {};
  for (let item of value) {
    let protocol = item.split(":")[0];
    let port = item.split(":")[1];
    if (!["UDP", "TCP"].includes(protocol)) {
      return `Please select a protocol`;
    }
    if (isPort(port) !== undefined || port === "") {
      return `Invalid port list value for ${protocol}`;
    }
    if (protocol in lists) {
      lists[protocol].push(port);
    } else {
      lists[protocol] = [port];
    }
  }
  for(let k in  lists) {
    let s = new Set(lists[k]);
    if (lists[k].length !== s.size){
      return `Duplicated port value for ${k}`;
    }
  }
}

export const isBlockedDomain = (value: string) => {
  let domain = value.substring(value.lastIndexOf("@") + 1);
  return (blockedDomains.domains.indexOf(domain) > -1) ? 'This email domain is not supported' : undefined;
}

export const isAppsArray = (value: any) => {
  if (Array.isArray(value) && value?.length <= 0) {
    return "Please add atleast one application";
  }
}

export const isEmail =(email: string) => {
    var regexCode = /\S+@\S+\.\S+/;
    if(email !== undefined) {
      if(!regexCode.test(email)) {
        return `The email is invalid`;
      };
    } else {
      return "This field is required"
    }
}

const isFunction = (value: any) => typeof value === 'function';

export interface ValidationErrorMessageWithArgs {
    message: string;
    args: {
        [key: string]: ValidationErrorMessageWithArgs | any;
    };
}

export type ValidationErrorMessage = string | ValidationErrorMessageWithArgs;

export type Validator = (
    value: any,
    values: any,
    props: any
) =>
    | ValidationErrorMessage
    | null
    | undefined
    | Promise<ValidationErrorMessage | null | undefined>;

// type predicate, see https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
function isValidationErrorMessageWithArgs(
    error: ReturnType<Validator>
): error is ValidationErrorMessageWithArgs {
    // @ts-ignore
    return error.hasOwnProperty('message');
}

export const combine2Validators = (
    validator1: Validator,
    validator2: Validator
): Validator => {
    return (value, values, meta) => {
        const result1 = validator1(value, values, meta);
        if (!result1) {
            return validator2(value, values, meta);
        }
        if (
            typeof result1 === 'string' ||
            isValidationErrorMessageWithArgs(result1)
        ) {
            return result1;
        }

        return result1.then(resolvedResult1 => {
            if (!resolvedResult1) {
                return validator2(value, values, meta);
            }
            return resolvedResult1;
        });
    };
};

// Compose multiple validators into a single one for use with final-form
export const composeValidators = (...validators: any[]) => {
    const allValidators = (Array.isArray(validators[0])
            ? validators[0]
            : validators
    ).filter(isFunction) as Validator[];
    return allValidators.reduce(combine2Validators, () => null);
};

export const requiredValidator = memoize((message = '') =>
    Object.assign(
        (value: any, values: any) =>
            isEmpty(value)
                ? message
                : undefined,
        { isRequired: true }
    )
);

export const validateAwsAccounts = (value: string) => {
  if (!value) {
    return undefined; // Allow empty values
  }

  const accountRegex = /^\d{12}$/;
  const accounts = (Array.isArray(value)) ? value : value.split(',');
  if (accounts.length !== uniq(accounts).length) {
    return "Duplicate AWS account IDs are not allowed";
  }
  if (accounts.length > 100) {
    return "A maximum of 100 AWS account IDs can be allowlisted.";
  }
  const invalidAccounts = accounts.filter(account => !accountRegex.test(account));

  if (invalidAccounts.length > 0) {
    return "Please enter valid 12-digit AWS account IDs separated by commas";
  }

  return undefined;
};

export const validateCIDRList = (cidrList = '') => {
  if (typeof cidrList !== 'string' && ! Array.isArray(cidrList)) {
    return 'Input must be a string';
  } else if (!cidrList) {
    return undefined;
  }
  const cidrs = (Array.isArray(cidrList)) ? cidrList : cidrList.split(',');
  const invalidCIDRs: string[] = [];

  cidrs.forEach((cidr) => {
    if (!REGEX_IPV4_CIDRS.test(cidr)) {
      invalidCIDRs.push(`Invalid CIDR ${cidr}`);
    }
  });

  if (invalidCIDRs.length > 0) {
    return invalidCIDRs.join('; ');
  }

  if (uniq(cidrs.map((cidr) => cidr.trim())).length !== cidrs.length) {
    return 'There are repeated CIDR values';
  }
};

export const isValidIPAMPoolId = (value: any) => {
    if (!value) return undefined;
    const ipamPoolRegex = /^\bipam-pool-\w+\b$/;
    return ipamPoolRegex.test(value) ? undefined : 'Invalid Ipam Pool ID format. It should match the pattern ipam-pool-[a-zA-Z0-9]';
};

export const isNameUnique = (items: any[], value: string, initialName: string) => {
  if (!value) return undefined; // If no value, no validation error.
  if (value === initialName) return undefined; // Skip validation if the name hasn't changed in "edit" mode.
  const isDuplicate = items?.some(item => item?.Name === value);
  return isDuplicate ? 'Name must be unique' : undefined;
};

export const isValidNetworkAddress = (value: any) => {
  if (!value) return undefined; // If no value, no validation error.

  // Regular expression to validate CIDR notation
  const cidrRegex = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}\/([1-9]|[12][0-9]|3[0-2])$/;

  return cidrRegex.test(value) ? undefined : 'Invalid network address, should be in CIDR notation (e.g. 10.0.0.0/24)';
};

export const validSourceName = (value: any) => {
  const validChars = /^[a-zA-Z0-9 _-]*$/;
  return value && !validChars.test(value) ? 'Use only letters, numbers, spaces, hyphens, and underscores' : undefined;
};

export const firewallNameKeyNotAllowed = (value: any) => {
  return value && value.toLowerCase() === "firewallname" ? 'FirewallName cannot be used as tag key' : undefined;
};
