import { forwardRef, useEffect, useState } from "react";
import { IChipInitValue } from "../../types";
import { useTranslate} from '../../customHooks';
import { isEmpty } from "lodash";
import { PlusSquareIcon } from "@panwds/icons";
import { AddNewTagForm } from ".";
import { Button, Tag, Tooltip, useModal, Field } from "@panwds/react-ui";

const TagsWithAdd = forwardRef<HTMLInputElement, any>(
  ({ ...props }, ref) => {
  const {
    value,
    onChange,
    validate,
    label
  } = props;

  const translate = useTranslate();

  const MAX_TEXT_LENTGH = 10;
  const displayText = (text: String) => text?.length > MAX_TEXT_LENTGH ? text?.slice(0, MAX_TEXT_LENTGH) + '...': text;

  const [data, setData] = useState<IChipInitValue[]>(value ? value : [])

  useEffect(() => {
    if (value?.length !== data?.length) {
      setData(value);
    }
  }, [value])
    const handleAdd = (newTag: Record<string, any>): void => {
      const newData = [...data, newTag];
      onChange(newData);
      setData(newData);
    }
    const handleDelete = (event, idx: number): void => {
      event.stopPropagation();
      event.preventDefault();
      let findEl: IChipInitValue | undefined;
      const newData = [...data];
      findEl = newData.splice(idx, 1)[0];

      onChange(newData);
      setData(newData);
  }

  const { openModal } = useModal(AddNewTagForm);

  return (
    <div>
      <Field
        label={label}
        control={
          <div className="tw-flex tw-flex-wrap tw-gap-2">
            {
              data?.map(({ Key, Value }, idx: number) => {
                return (
                  <div key={idx}>
                    <Tooltip label={`${Key} | ${Value}`}>
                      <div>
                        <Tag
                          data-test-id={`tag-${idx}`}
                          closeButtonProps={{
                            onClick: (e) => handleDelete(e,idx)
                          }}
                        > {`${displayText(Key)} | ${displayText(Value)}`}
                        </Tag>
                      </div>
                    </Tooltip>
                  </div>
                );
              })
            }
          </div>
        }
      />
      <div className={!isEmpty(data) ? "tw-mt-2" : ""}>
        <Button icon={<PlusSquareIcon size="sm" />}
          appearance="secondary"
          size="sm"
          onClick={(e) => {openModal({
            onSubmit: handleAdd,
            title: translate('chip.addNewTag'),
            validate: validate || undefined,
            data: data,
            })
          }}>
          {translate('chip.add')}
        </Button>
      </div>

    </div>
  );
});

export default TagsWithAdd;
