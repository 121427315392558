import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { buildError, prepareHeaders, API } from '../../api/apis';
import * as DataModels from "../../api/FwaasDataModels";
import { ListRuleStacksRequest } from '../../interfaces/interfaces';

// Define the request and response interfaces based on your API
interface AssociateLinkRequest {
    region: string;
    firewall_id: string;
    link_request: any;
}

interface DisassociateLinkRequest {
    region: string;
    firewall_id: string;
    link_request: any;
}

interface AssociateRuleStackRequest {
    region: string;
    firewall_id: string;
    rulestack_request: any;
}

interface DisassociateRuleStackRequest {
    region: string;
    firewall_id: string;
    rulestack_request: any;
}

export const policiesService = createApi({
    reducerPath: 'linksApi',
    baseQuery: fetchBaseQuery({
        prepareHeaders: prepareHeaders,
    }),
    tagTypes: ['Links'],
    endpoints: (builder) => ({
        getRuleStacks: builder.query({
            query: (payload: ListRuleStacksRequest) => {
                const result = API.FETCH_RULESTACKS(payload);
                return result;
            },
            transformResponse: (response: any) => {
                return response.Response.RuleStackRunning || [];
            },
            transformErrorResponse: (response) => {
                return buildError(response, "");
            }
        }),
        associateLink: builder.mutation({
            query: (request: AssociateLinkRequest) => ({
                url: API.ASSOCIATE_LINK(request.firewall_id),
                method: 'POST',
                body: request.link_request,
                params: { region: request.region },
            }),
            transformErrorResponse: (response) => {
                return buildError(response, "");
            },
            invalidatesTags: ['Links'],
        }),
        disassociateLink: builder.mutation({
            query: (request: DisassociateLinkRequest) => ({
                url: API.DISASSOCIATE_LINK(request.firewall_id),
                method: 'DELETE',
                body: request.link_request,
                params: { region: request.region },
            }),
            transformErrorResponse: (response) => {
                return buildError(response, "");
            },
            invalidatesTags: ['Links'],
        }),
        associateRuleStack: builder.mutation({
            query: (request: AssociateRuleStackRequest) => ({
                url: API.ASSOCIATE_RULESTACK(request.firewall_id),
                method: 'POST',
                body: request.rulestack_request,
                params: { region: request.region },
            }),
            transformErrorResponse: (response) => {
                return buildError(response, "");
            },
            invalidatesTags: ['Links'],
        }),
        disassociateRuleStack: builder.mutation({
            query: (request: DisassociateRuleStackRequest) => ({
                url: API.DISASSOCIATE_RULESTACK(request.firewall_id),
                method: 'DELETE',
                body: request.rulestack_request,
                params: { region: request.region },
            }),
            transformErrorResponse: (response) => {
                return buildError(response, "");
            },
            invalidatesTags: ['Links'],
        }),
        getLinks: builder.query({
            query: () => ({
                url: API.FETCH_LINKS(),
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response?.Response?.Links || [];
            },
            transformErrorResponse: (response) => {
                return buildError(response, "");
            }
        })
    }),
});

export const {
    useGetRuleStacksQuery,
    useAssociateLinkMutation,
    useDisassociateLinkMutation,
    useAssociateRuleStackMutation,
    useDisassociateRuleStackMutation,
    useGetLinksQuery
} = policiesService;
