import { FormControl, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { find } from "lodash";
import { FC, useEffect, useState, Fragment } from "react";
import { Field } from "react-final-form";
import { useHistory, withRouter } from "react-router-dom";
import { Button as PANWDSButton } from "@panwds/react-ui";
import * as EndPoints from "../../api/FwaasUriEndPoints";
import {
  PANTile,
  PANTitle,
  SaveButton,
    toast
} from "../../components";
import { Row } from "../../components/FormElements";
import { dataProvider } from "../../dataProvider";
import {PANWDSInput, PANWDSForm, PANWDSBreadcrumbs, PANWDSSelectWithSearch} from "../../components/PANWDSElements";
import { isBlockedDomain, isEmail, isRequired, composeValidators, requiredValidator } from "../../utils/validate";
import { useTranslate } from '../../customHooks';
import {useAppDispatch} from "../../app/hooks";
import {ReduxActions, ReduxResources} from "../../redux";
import { useGetUserRolesAndPermissionsQuery } from '../../redux/services/user-roles-service';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "end",
    "-webkit-justify-content": "flex-end",
    alignItems: "start",
    boxSizing: "border-box",
    padding: '10px 0',
    minHeight: "initial",
  },
}));

interface UsersCreateToolbarProps {
  submitting: boolean;
  [key: string]: any;
}

const UsersCreateToolbar: FC<UsersCreateToolbarProps> = ({
  submitting,
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();
  return (
    <div className={classes.toolbar}>
      <PANWDSButton
        size="md"
        appearance="secondary"
        disabled={submitting}
        onClick={() => {
          history.goBack();
        }}
        dataMetrics="cloudngfw-users-create-cancel"
      >
        {translate(`resources.users.fields.Cancel`)}
      </PANWDSButton>
      <SaveButton
        appearance="primary"
        size="md"
        label={translate(`resources.users.fields.Create`)}
        data-metrics={"cloudngfw-users-create-create"}
        submitOnEnter={true}
        loading={submitting}
        dataMetrics="cloudngfw-users-create-save"
        {...props}
      />
    </div>
  );
};

const handleRolesInputFormatter = (value: any) => {
  const values = value.split('/');
  let o: any = {};
  if (values.length > 1) {
    o.AccountId = values[0];
    o.Policy = values[1];
  } else {
    o.Policy = values[0];
  }
  return o;
}

const UsersCreate = (props: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { data: roleChoices, error: roleChoicesnError, isLoading: roleChoicesLoading } = useGetUserRolesAndPermissionsQuery();

    const saveUser = async (values: any) => {
      setSubmitting(true);
      values.Permissions = values.Permissions.map((item: string) => handleRolesInputFormatter(item));
        await dispatch(ReduxActions.create({resource: ReduxResources.USER})({data: values})).unwrap()
            .then((response) => {
                toast.success("User created successfully");
                history.goBack();
            })
            .catch(error => {
                setSubmitting(false);
                toast.error(error?.error, {toastId: "users-create"});
            });
    };

  return (
    <>
      <PANTitle divider={false} />
      <PANWDSBreadcrumbs
        mapping={{
          users: translate(`resources.users.name`),
          create: translate(`resources.users.fields.CreateButton`),
        }}
      />
      <PANTitle
        title={translate(`resources.users.fields.CreateButton`)}
        divider
      />
        <PANWDSForm
            toolbar={<UsersCreateToolbar submitting={submitting} />}
            onSubmit={saveUser}
            style={{ margin: 16 }}
        >
            <Fragment>
                <Grid container style={{ width: "auto" }}>
                    <PANTile size={12}>
                        <Row>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Field
                                        title={translate(`resources.users.fields.FirstName`)}
                                        dataMetrics="cloudngfw-users-create-field-first-name"
                                        name="FirstName"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        validate={requiredValidator(translate('validation.required'))}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="LastName"
                                        title={translate(`resources.users.fields.LastName`)}
                                        dataMetrics="cloudngfw-users-create-field-last-name"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        validate={requiredValidator(translate("validation.required"))}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Field
                                        name="UserName"
                                        title={translate(`resources.users.fields.UserName`)}
                                        dataMetrics="cloudngfw-users-create-field-email"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        validate={composeValidators(
                                            isRequired,
                                            isBlockedDomain,
                                            isEmail
                                        )}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Field
                                      name="Permissions"
                                      row
                                      // @ts-ignore
                                      component={PANWDSSelectWithSearch}
                                      title={translate(`resources.users.fields.Roles`)}
                                      items={roleChoices}
                                      loading={roleChoicesLoading}
                                      enableArrayInput
                                      enableMultiSelect
                                    />
                                </FormControl>
                            </Grid>
                        </Row>
                    </PANTile>
                </Grid>
            </Fragment>
        </PANWDSForm>
    </>
  );
};

export default withRouter(UsersCreate);
